import i18next from 'i18next'
import commonEN from './locales/en/common.json'
import commonRU from './locales/ru/common.json'
import idxEN from './locales/en/idx.json'
import idxRU from './locales/ru/idx.json'

const resources = {
  en: {
    common: commonEN,
    idx: idxEN,
  },
  ru: {
    common: commonRU,
    idx: idxRU,
  }
}

i18next.init({
  lng: gon.locale,
  debug: gon.environment === 'development',
  ns: ['common', 'idx'],
  defaultNS: 'common',
  resources,
})

export { i18next }
